export class Locations {
    static fromJsonToObject(jsonLocations) {
        return jsonLocations.map(jsonLocation => _Location.fromJson(jsonLocation))
    }   

    static getLocationsCoordinates(locations) {
        return locations.map(locations => [locations.latitude, locations.longitude])
    }

    static getLocationsInformation(locations) {
        return locations.map(location => 
            [
                location.location_name, 
                location.full_address, 
                location.country,
                location.source_link
            ]
        )
    }
}

class _Location {
    static fromJson(json) {
        return {
            country: json.country,
            latitude: json.lat, 
            longitude: json.long,
            location_name: json.location_name,
            source_link: json.source_link,
            full_address: json.full_address
        }
    }
}